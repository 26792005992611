import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react';
import { AuthContext } from '../../context/authContext';
import { Context } from '../../types/interfaces';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Spinner from '../partials/Spinner';
import styles from './BankID.module.scss';
import { getBankIDStatusMessage } from '../../utils/utilFunctions';
import { checkCurrentUser } from '../../utils/CheckCurrentUser';

interface qrCodes {
  qrStartToken: string;
  time: string;
  qrAuthCode: string;
}

const BankId: React.FC<RouteComponentProps> = () => {
  const [error, setError] = useState('');
  const [authStatus, setAuthStatus] = useState('');
  const [authInProgress, setAuthInProgress] = useState(false);
  const [usingQr, setUsingQr] = useState(false);
  const [qrCodes, setqrCodes] = useState<qrCodes>();
  const context = useContext(AuthContext) as Context;
  const history = useHistory();
  useEffect(() => {
    if (context && !context.authStatus) {
      (async () => {
        try {
          const user = await checkCurrentUser();
          if (user) {
            context.setAuthStatus(true);
            context.setRole(user.role);
            user.allowedLogins && context.setAllowedLogins(user.allowedLogins);
            history.push('/mina-sidor');
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [context, history]);

  useEffect(() => {
    if (context && context.authStatus) {
      history.push('/mina-sidor');
    }
  }, [context, history]);

  const pollCollectStatus = async (orderRef: string, useQr: boolean) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/bankid/collect/`,
        {
          orderRef
        }
      );

      const { status, hintCode, givenName, surname, role } = res.data;

      if (status === 'pending') {
        setAuthStatus(
          getBankIDStatusMessage(status, hintCode, context.autostarting)
        );

        if (useQr) {
          const qrRes = await axios.get(
            `${process.env.REACT_APP_SERVER}/api/bankid/generateQr/`
          );

          setqrCodes({
            qrStartToken: qrRes.data.qrStartToken,
            time: qrRes.data.qrSecondsSinceAuth,
            qrAuthCode: qrRes.data.qrAuthCode
          });
        }

        setTimeout(() => {
          pollCollectStatus(orderRef, useQr);
        }, 2000);
      } else if (givenName && surname && role >= 0) {
        setAuthInProgress(false);
        setUsingQr(false);
        context.setAutostarting(false);
        context.setAuthStatus(true);
        context.setRole(res.data.role);
        context.setAllowedLogins(res.data.allowedLogins);
        history.push('/mina-sidor');
      } else if (status === 'failed') {
        setAuthInProgress(false);
        setUsingQr(false);
        context.setAutostarting(false);
        setError(
          getBankIDStatusMessage(status, hintCode, context.autostarting)
        );
        setTimeout(() => {
          setError('');
        }, 5000);
      } else {
        setAuthInProgress(false);
        setUsingQr(false);
        context.setAutostarting(false);
        setAuthStatus(
          getBankIDStatusMessage(status, hintCode, context.autostarting)
        );
      }
    } catch (error) {
      setAuthInProgress(false);
      setUsingQr(false);
      context.setAutostarting(false);
      setError(
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message
      );
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };

  const initAuthThisDevice = async (): Promise<void> => {
    setAuthInProgress(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/bankid/auth/`,
        {
          useQr: false
        }
      );

      const iOS =
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

      const autoStartUrl = !iOS
        ? `bankid:///?autostarttoken=${res.data.autoStartToken}&redirect=null`
        : `https://app.bankid.com/?autostarttoken=${res.data.autoStartToken}&redirect=null`;

      const win = window.open(autoStartUrl, '_self');
      if (win != null) {
        win.focus();
      }

      context.setAutostarting(true);
      pollCollectStatus(res.data.orderRef, false);
    } catch (error) {
      console.error(error);
      setError('Inloggning misslyckades. Vänligen försök igen.');
      setAuthInProgress(false);
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  };

  const initAuthOtherDevice = async (): Promise<void> => {
    context.setAutostarting(false);
    setAuthInProgress(true);
    setUsingQr(true);
    const useQr = true;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/bankid/auth/`,
        {
          useQr: true
        }
      );

      setqrCodes({
        qrStartToken: res.data.qrStartToken,
        time: res.data.qrSecondsSinceAuth,
        qrAuthCode: res.data.qrAuthCode
      });

      pollCollectStatus(res.data.orderRef, useQr);
    } catch (error) {
      console.error(error);
      setError('Något gick fel, vänligen försök igen.');
      setAuthInProgress(false);
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  };

  return (
    <main className={styles.main}>
      {!authInProgress ? (
        <div className={styles.formContainer}>
          <h2>Inloggning med BankID</h2>
          <button
            className={styles.button}
            onClick={() => initAuthThisDevice()}
            type="button"
            disabled={authInProgress}
          >
            {authInProgress ? (
              <Spinner width="auto" />
            ) : (
              'BankID på den här enheten'
            )}
          </button>
          <button
            className={styles.button}
            onClick={() => initAuthOtherDevice()}
            type="button"
            disabled={authInProgress}
          >
            {authInProgress ? (
              <Spinner width="auto" />
            ) : (
              'BankID på en annan enhet'
            )}
          </button>
          <p className={styles.errorMessage}>{error ? error : null}</p>
        </div>
      ) : usingQr && qrCodes && qrCodes.qrAuthCode ? (
        <div className={styles.formContainer}>
          <QRCodeSVG
            value={`bankid.${qrCodes ? qrCodes.qrStartToken : ''}.${
              qrCodes ? qrCodes.time : '0'
            }.${qrCodes ? qrCodes.qrAuthCode : ''}`}
          />
          <p className={styles.statusMessage}>{authStatus}</p>
        </div>
      ) : (
        <div className={styles.formContainer}>
          <Spinner />
          <p className={styles.statusMessage}>{authStatus}</p>
        </div>
      )}
    </main>
  );
};
export default BankId;
